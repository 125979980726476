exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-board-js": () => import("./../../../src/pages/company-board.js" /* webpackChunkName: "component---src-pages-company-board-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investments-js": () => import("./../../../src/pages/investments.js" /* webpackChunkName: "component---src-pages-investments-js" */),
  "component---src-pages-job-offer-js": () => import("./../../../src/pages/job-offer.js" /* webpackChunkName: "component---src-pages-job-offer-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */)
}

